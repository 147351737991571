import React from 'react';
import { PencilIcon, BriefcaseIcon } from '@heroicons/react/24/outline'; // Ensure correct import and size

interface SetTextareaModeToggleProps {
  editMode: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

function SetTextareaModeToggle({ editMode, setEditMode }: SetTextareaModeToggleProps) {
  return (
    <div className="flex space-x-1 rounded-lg bg-slate-100 p-0.5" role="tablist" aria-orientation="horizontal">
      {/* Edit Button */}
      <button 
        className={`flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-semibold lg:pr-3 ${editMode ? 'bg-white shadow' : ''}`}
        onClick={() => setEditMode(true)}
        aria-selected={editMode}
      >
        <PencilIcon className="h-4 w-4" /> {/* Adjust size if needed */}
       <span className="hidden lg:block lg:not-sr-only lg:ml-2 text-slate-900">Write</span>
      </button>

      {/* Save Button */}
      <button 
        className={`flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-semibold lg:pr-3 ${!editMode ? 'bg-white shadow' : ''}`}
        onClick={() => setEditMode(false)}
        aria-selected={!editMode}
      >
        <BriefcaseIcon className="h-4 w-4" /> {/* Adjust size if needed */}
        <span className="hidden lg:block lg:not-sr-only lg:ml-2 text-slate-900">Select & Save</span>
      </button>
    </div>
  );
}

export default SetTextareaModeToggle;
