// debounce.ts

type Procedure = (...args: any[]) => void;

export function debounce<F extends Procedure>(
  func: F,
  waitFor: number
): (this: ThisParameterType<F>, ...args: Parameters<F>) => ReturnType<F> {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  return function(this: ThisParameterType<F>, ...args: Parameters<F>) {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, waitFor);
  } as unknown as (this: ThisParameterType<F>, ...args: Parameters<F>) => ReturnType<F>;
}
