import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { addCategoryToShadowList } from '../utilities/StorageController';
import { PlusIcon } from '@heroicons/react/24/outline';

const AddCategoryButton = () => {
  const [newCategory, setNewCategory] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const handleAddCategory = () => {
    addCategoryToShadowList({ name: newCategory });
    setNewCategory('');
    setIsOpen(false);
  };

  return (
    <>
      <button onClick={() => setIsOpen(true)} className="m-1 px-4 bg-gray-50 hover:bg-gray-100 text-gray-700 rounded">
        <PlusIcon className="h-4 w-4 p-0 text-black/50 mr-1" />
      </button>
      <Transition show={isOpen} as={React.Fragment}>
        <Dialog onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={React.Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-black opacity-30"
              onClick={() => setIsOpen(false)} // Handle click to dismiss the dialog
            />
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="transition-transform transform ease-out duration-300"
            enterFrom="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
            enterTo="translate-y-0 opacity-100 sm:scale-100"
            leave="transition-transform transform ease-in duration-200"
            leaveFrom="translate-y-0 opacity-100 sm:scale-100"
            leaveTo="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
          >
            <div className="fixed z-10 inset-x-0 bottom-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
              <div className="bg-white rounded-md overflow-hidden shadow-xl sm:max-w-lg sm:w-full">
                <div className="px-4 py-6 sm:px-6">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Add Category</h3>
                  <div className="mt-6">
                    <input
                      type="text"
                      className="border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 w-full"
                      placeholder="New Category"
                      value={newCategory}
                      onChange={(e) => setNewCategory(e.target.value)}
                    />
                  </div>
                </div>
                <div className="px-4 py-3 sm:px-6">
                  <div className="flex justify-end">
                    <button
                      onClick={() => setIsOpen(false)}
                      className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mr-3 sm:text-sm"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleAddCategory}
                      className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};

export default AddCategoryButton;
