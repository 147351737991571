import React from 'react';
import { ArrowPathIcon } from '@heroicons/react/20/solid';

interface ClearButtonProps {
    textAreasRef: React.MutableRefObject<(HTMLTextAreaElement | null)[]>; // Ref to the array of textareas to clear
    texts: { id: number; name: string; content: string }[];
    setTexts: React.Dispatch<React.SetStateAction<{ id: number; name: string; content: string }[]>>;
    setSentences: React.Dispatch<React.SetStateAction<string[][]>>;
    setMatchedSentences: React.Dispatch<React.SetStateAction<{ [textareaId: number]: string[] }>>;
}

export const ClearButton: React.FC<ClearButtonProps> = ({ textAreasRef, texts, setTexts, setSentences, setMatchedSentences }) => {
    const handleClearClick = () => {
        const newTexts = texts.map(text => ({ ...text, content: '' }));
        setTexts(newTexts);

        newTexts.forEach((text, index) => {
            const textarea = textAreasRef.current[index];
            if (textarea) {
                // Clear the value of each textarea
                textarea.value = '';

                // Clear the sentences for the current textarea
                setSentences(prevSentences => {
                    const updatedSentences = [...prevSentences];
                    updatedSentences[index] = [];
                    return updatedSentences;
                });

                // Update the matched sentences
                setMatchedSentences(prev => ({ ...prev, [index + 1]: [] }));
            }
        });
    };

    return (
        <button
            type="button"
            onClick={handleClearClick}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600/20 hover:bg-red-700/20 text-red-800 px-2.5 py-1.5 text-sm font-semibold shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
        >
            <ArrowPathIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
            Clear
        </button>
    );
};

