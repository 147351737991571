import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { getShadowCategoryList, removeCategoryFromShadowList } from '../utilities/StorageController';
import { EllipsisVerticalIcon, BackspaceIcon } from '@heroicons/react/24/outline';

interface Category {
  name: string;
}

const CategoryItem: React.FC<{ category: Category, onDelete: (name: string) => void }> = ({ category, onDelete }) => {
  return (
    <li className="flex justify-between items-center mb-2 py-2 border-b">
      <span>{category.name}</span>
      <button className="text-red-500" onClick={() => onDelete(category.name)}>
        <BackspaceIcon className='h-5 w-5'></BackspaceIcon>
      </button>

    </li>
  );
};

const RemoveCategoryButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [categories, setCategories] = useState<Category[]>(getShadowCategoryList());

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleDeleteCategory = (categoryName: string) => {
    removeCategoryFromShadowList(categoryName);
    setCategories(getShadowCategoryList());
  };

  return (
    <>
      <button onClick={openModal}><EllipsisVerticalIcon className="w-4 h-4 text-black"></EllipsisVerticalIcon></button>
      <Dialog open={isOpen} onClose={closeModal} className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen">
          {/* Enhanced overlay styling for better contrast */}
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-50 z-10" />

          {/* Added shadow and adjusted padding for clearer modal appearance */}
          <div className="bg-white rounded-lg p-6 max-w-md w-full shadow-xl z-20">
            <Dialog.Title className="text-lg font-semibold mb-4">Remove Categories</Dialog.Title>
            <ul>
              {categories.map(category => (
                <CategoryItem key={category.name} category={category} onDelete={handleDeleteCategory} />
              ))}
            </ul>
            <button className="mt-4 bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded" onClick={closeModal}>Close</button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default RemoveCategoryButton;
