export const saveToLocalStorage = (text: string) => {
  if (typeof text !== 'string') {
    throw new Error('Expected a string value for saving to local storage.');
  }
  
  // Get the existing texts from local storage or initialize as an empty array if not found
  const storedTexts = JSON.parse(localStorage.getItem("texts") || "[]");

  // Check if the text is already in the stored texts to prevent duplicates
  if (!storedTexts.includes(text)) {
    // If the text is not present, push the new text into the array
    storedTexts.push(text);
    // Save the updated array back to local storage
    localStorage.setItem("texts", JSON.stringify(storedTexts));
  } else {
    // If the text is already present, you might want to handle it differently
    console.warn('Attempted to save duplicate text to local storage:', text);
  }
};

export const getFromLocalStorage = (): string[] => {
  return JSON.parse(localStorage.getItem("texts") || "[]");
};

export const clearLocalStorage = () => {
  const confirmed = window.confirm("Are you sure you want to delete all local storage data?");
  if (confirmed) {
    localStorage.clear();
    // Wait for 1 second (adjust delay time as needed)
    setTimeout(() => {
      dispatchLocalStorageUpdate();
    }, 500); // 1000 milliseconds = 1 second
  } else {
    // Do nothing or handle cancellation
  }
};


interface Category {
  name: string;
}

interface TextSnippet {
  text: string;
  category: string;
  clicks: number;
  icon: string;
  categoryColor: string; // Add categoryColor property
}

interface DataToExport {
  [key: string]: any; // or replace `any` with a more specific type if you know the structure of your data
}


const dispatchLocalStorageUpdate = () => {
  window.dispatchEvent(new Event('localstorage-update'));
  console.log("dispatched")
};

export const saveSentenceToLocal = (sentence: string, category: string, categoryColor: string, categoryIcon: string = "💬", focusedTextarea: number, setEditMode: (mode: boolean) => void): void => {
  const key = `sentences_${focusedTextarea}`;
  const sentences: TextSnippet[] = JSON.parse(localStorage.getItem(key) || '[]');
  const newSentence: TextSnippet = { text: sentence, category, clicks: 0, icon: categoryIcon, categoryColor }; // Include categoryColor
  sentences.push(newSentence);
  localStorage.setItem(key, JSON.stringify(sentences));
  dispatchLocalStorageUpdate();
  setEditMode(true); // Set editMode to true after saving
};

export const saveSentenceBlockToLocal = (sentenceBlock: string[], category: string, categoryColor: string, categoryIcon: string = "💬", focusedTextarea: number, setEditMode: (mode: boolean) => void): void => {
  const key = `sentences_${focusedTextarea}`;
  // Attempt to retrieve existing blocks from local storage or initialize an empty array if none found
  let blocks: TextSnippet[];
  try {
    blocks = JSON.parse(localStorage.getItem(key) || '[]');
  } catch (error) {
    console.error('Error parsing existing blocks from local storage:', error);
    blocks = []; // Fallback to an empty array in case of error
  }
  
  // Combine the array of sentences into a single string
  const combinedSentence: string = sentenceBlock.join(' ');
  
  // Log the combined sentence for debugging purposes
  console.log("The combined sentence is:", combinedSentence);
  
  // Create a new TextSnippet object with the combined sentence and other provided details
  const newSentence: TextSnippet = { text: combinedSentence, category, clicks: 0, icon: categoryIcon, categoryColor }; // Include categoryColor
  
  // Add the new TextSnippet object to the array of blocks
  blocks.push(newSentence);
  
  localStorage.setItem(key, JSON.stringify(blocks));
  dispatchLocalStorageUpdate();
  setEditMode(true); // Set editMode to true after saving
};





export const deleteSentenceFromLocal = (sentenceToDelete: string, focusedTextarea: number): void => {
  const key = `sentences_${focusedTextarea}`;
  let sentences: TextSnippet[] = JSON.parse(localStorage.getItem(key) || '[]');
  
  // Filter out the sentence to delete
  sentences = sentences.filter(sentence => sentence.text !== sentenceToDelete);
  
  // Save the updated sentences back to local storage
  localStorage.setItem(key, JSON.stringify(sentences));
  
  // Optionally, you can dispatch an event to notify parts of your application that the local storage has been updated
  dispatchLocalStorageUpdate();
};

export const getAllCategories = (): string[] => {
  console.log("getting all categories")
  const categories = getShadowCategoryList();
  return categories.map(category => category.name);
};


const CATEGORY_LIST_KEY = "shadowCategoryList";

export const getShadowCategoryList = (): Category[] => {
  return JSON.parse(localStorage.getItem(CATEGORY_LIST_KEY) || "[]");
};

export const addCategoryToShadowList = (newCategory: Category): void => {
  if (!newCategory.name.trim()) {
    console.warn('Cannot add an empty category');
    return;
  }

  let categories = getShadowCategoryList();
  if (!categories.some(category => category.name === newCategory.name)) {
    categories.push(newCategory);
    localStorage.setItem(CATEGORY_LIST_KEY, JSON.stringify(categories));
    dispatchLocalStorageUpdate();
  } else {
    console.warn('Category already exists:', newCategory.name);
  }
};

export const removeCategoryFromShadowList = (categoryName: string): void => {
  let categories = getShadowCategoryList();
  
  // Filter out the category to remove
  categories = categories.filter(category => category.name !== categoryName);
  
  // Update the local storage
  localStorage.setItem(CATEGORY_LIST_KEY, JSON.stringify(categories));
  
  // Set the category of corresponding items to an empty string
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && (key.startsWith("sentences_") || key.startsWith("sentenceBlocks_"))) {
      try {
        let items: TextSnippet[] = JSON.parse(localStorage.getItem(key) || '[]');
        items.forEach(item => {
          if (item.category === categoryName) {
            item.category = "";
          }
        });
        localStorage.setItem(key, JSON.stringify(items));
      } catch (error) {
        console.error(`Error updating data for key "${key}": `, error);
        // Handle or log the error as needed
      }
    }
  }
  
  // Dispatch event to notify parts of your application
  dispatchLocalStorageUpdate();
};


export const exportToLocalFile = () => {
  let dataToExport: DataToExport = {}; // Apply the DataToExport interface here

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && (key.startsWith("sentences_") || key === "shadowCategoryList")) {
      try {
        const value = localStorage.getItem(key);
        if (value) {
          // Assuming all values are JSON strings
          dataToExport[key] = JSON.parse(value);
        }
      } catch (error) {
        console.error(`Error parsing data for key "${key}": `, error);
        // Handle or log the error as needed
      }
    }
  }

  if (Object.keys(dataToExport).length) {
    const currentDate = new Date();
    const dateString = currentDate.toISOString().slice(0, 10); // Format: YYYY-MM-DD
    const filename = 'Feedback Fan - exported on ' + dateString + '.feedbackfan';

    const blob = new Blob([JSON.stringify(dataToExport, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    
  } else {
    console.error('No data to export.');
  }
};





export const importFromLocalFile = (file: File) => {
  const reader = new FileReader();
  reader.onload = () => {
    const importedData = reader.result as string;
    try {
      localStorage.clear(); // Clear existing local storage
      const parsedData = JSON.parse(importedData);
      for (const key in parsedData) {
        if (Object.prototype.hasOwnProperty.call(parsedData, key)) {
          localStorage.setItem(key, JSON.stringify(parsedData[key]));
        }
      }
      console.log('Data imported successfully.');
      dispatchLocalStorageUpdate(); // Move this line here
    } catch (error) {
      console.error('Error parsing imported data or writing to local storage:', error);
    }
  };
  reader.onerror = () => {
    console.error('Error occurred while reading the file.');
  };
  reader.readAsText(file);
};





