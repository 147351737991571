import React, { useState, useEffect, useCallback } from "react";
import IdentifiedSentence from "./IdentifiedSentence";
import {
  saveSentenceBlockToLocal,
  saveSentenceToLocal,
} from "../utilities/StorageController";
import {
  BookmarkSquareIcon,
  BookmarkIcon,
  ArrowPathIcon,
  CursorArrowRaysIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import ComboBox from "./CategoryCombobox";

interface EmojiSelectorProps {
  selectedIcon: string;
  selectedColor: string; // Add selectedColor prop
  toggleIconFilter: (emoji: string, color: string) => void; // Modify toggleIconFilter function signature
}

const EmojiSelector: React.FC<EmojiSelectorProps> = ({
  selectedIcon,
  selectedColor,
  toggleIconFilter,
}) => {
  const emojis = [
    { emoji: '🚨', label: 'Hmm..', color: 'bg-red-100/60' },
    { emoji: '😐', label: 'OK', color: 'bg-orange-100/60' },
    { emoji: '👍', label: 'Great', color: 'bg-blue-100/60' },
    { emoji: '🙌', label: 'Super', color: 'bg-green-100/60' },
  ];

  return (
    <>
    <div>
      {emojis.map((emoji, index) => (
        <div
          key={emoji.label}
          onClick={() => toggleIconFilter(emoji.emoji, emoji.color)} // Pass color to toggleIconFilter
          className={`cursor-pointer hover:bg-gray-200 relative inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10 ${
            selectedIcon === emoji.emoji ? 'bg-gray-400' : 'bg-white'
          } ${index === 0 ? 'rounded-l-md ' : ''} ${index === emojis.length - 1 ? 'rounded-r-md' : '-ml-px'}`}
          style={{ color: emoji.color }} // Set color of the emoji
        >
          {emoji.emoji}
        </div>
      ))}
    
      </div>
    </>
    
  );
};

interface IdentifiedSentencesProps {
  sentences: string[];
  focusedTextarea: number;
  onResetSelection: () => void;
  resetSelection: boolean;
  textAreaName: string;
  setEditMode: (mode: boolean) => void;
}

const IdentifiedSentences: React.FC<IdentifiedSentencesProps> = ({
  sentences,
  focusedTextarea,
  onResetSelection,
  resetSelection,
  textAreaName,
  setEditMode,
}) => {
  const [selectedSentences, setSelectedSentences] = useState<number[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedIcon, setSelectedIcon] = useState<string>("");
  const [selectedColor, setSelectedColor] = useState<string>(""); // Add selectedColor state

  const toggleSentenceSelection = (index: number) => {
    setSelectedSentences((prevSelection) =>
      prevSelection.includes(index)
        ? prevSelection.filter((i) => i !== index)
        : [...prevSelection, index]
    );
  };

  const resetSelectionHandler = useCallback(() => {
    setSelectedSentences([]);
    onResetSelection();
  }, [onResetSelection]);

  useEffect(() => {
    if (resetSelection) {
      resetSelectionHandler();
    }
  }, [resetSelection, resetSelectionHandler]);

  useEffect(() => {
    resetSelectionHandler();
  }, [focusedTextarea, resetSelectionHandler]);

  const saveIndividualSentences = () => {
    selectedSentences.forEach((index) => {
      const sentence = sentences[index];
      saveSentenceToLocal(
        sentence,
        selectedCategory,
        selectedColor, // Pass selectedColor to save functions
        selectedIcon,
        focusedTextarea,
        setEditMode
      );
    });
  
    // Reset selection after saving
    resetSelectionHandler();
  };
  
  const saveSentenceBlock = () => {
    const sentenceBlock = selectedSentences.map((index) => sentences[index]);
    saveSentenceBlockToLocal(
      sentenceBlock,
      selectedCategory,
      selectedColor, // Pass selectedColor to save functions
      selectedIcon,

      focusedTextarea,
      setEditMode
    );
  
    // Reset selection after saving
    resetSelectionHandler();
  };

  const selectAllSentences = () => {
    setSelectedSentences([...Array(sentences.length).keys()]);
  };

  const handleCategoryChange = (category: string | null) => {
    if (category === null) {
      setSelectedCategory("");
    } else {
      setSelectedCategory(category);
    }
  };

  const toggleIconFilter = (emoji: string, color: string) => {
    setSelectedIcon(emoji === selectedIcon ? "" : emoji);
    setSelectedColor(color);
  };

  return (
    <div className="border border-gray-200 rounded mb-5 relative">
      <div className="space-x-2 px-2 m-2 py-1 flex justify-between items-center border-gray-200 ">
        <h2 className="text-base font-medium leading-7 text-slate-900">
          {textAreaName}
        </h2>
        <div className="flex gap-x-1.5  items-center">
        <button
            onClick={selectAllSentences}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-slate-100 px-2.5 py-1.5 text-sm font-semibold text-slate-700 shadow-sm hover:bg-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-300"
          >
            <CursorArrowRaysIcon className="h-5 w-5" aria-hidden="true" />
            <span className="hidden lg:block">Select All</span>
          </button>
          <button
            onClick={resetSelectionHandler}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-slate-100 px-2.5 py-1.5 text-sm font-semibold text-slate-700 shadow-sm hover:bg-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-300"
          >
            <ArrowPathIcon className="h-5 w-5" aria-hidden="true" />
            <span className="hidden lg:block">Deselect All</span>
          </button>
          <div className="text-gray-400 mx-4">
            |
          </div>
          <div className="hidden lg:block" >
          <EmojiSelector selectedIcon={selectedIcon} selectedColor={selectedColor} toggleIconFilter={toggleIconFilter} />
          </div>
          <ComboBox onSelectionChange={handleCategoryChange} />
          <button
            onClick={saveIndividualSentences}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-slate-100 px-2.5 py-1.5 text-sm font-semibold text-slate-700 shadow-sm hover:bg-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-300"
          >
            <BookmarkIcon className="h-5 w-5" aria-hidden="true" />
            <span className="hidden lg:block">Save Separately</span>
          </button>
          <button
            onClick={saveSentenceBlock}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-slate-100 px-2.5 py-1.5 text-sm font-semibold text-slate-700 shadow-sm hover:bg-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-300"
          >
            <BookmarkSquareIcon className="h-5 w-5" aria-hidden="true" />
            <span className="hidden lg:block">Save as One</span>
          </button>
        
        </div>
      </div>
      <div className="w-full h-full min-h-48 bg-slate-100/90 p-2 border border-slate-200 ">
        {sentences.map((sentence, index) => (
          <IdentifiedSentence
            key={index}
            sentence={sentence}
            isCurrent={selectedSentences.includes(index)}
            onClick={() => toggleSentenceSelection(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default IdentifiedSentences;
