import React, { useState, useRef, useEffect, useCallback } from "react";
import IdentifiedSentences from "./components/IdentifiedSentencesContainer";
import SetTextareaModeToggle from "./components/TextBoxModeButton";
import { CopyButton } from "./components/CopyStateButton";
import SentenceWithCategoryUpdate from "./components/SentenceWithCategory";
import {
  MagnifyingGlassIcon,
  ClipboardIcon,
  BriefcaseIcon,
  FaceFrownIcon,
  BarsArrowUpIcon,
  SparklesIcon,
  BackspaceIcon,
  ArrowUturnRightIcon,
  ArrowUturnLeftIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  replaceSentenceInText,
  extractSentences,
  identifyCurrentSentence,
  findSentenceBoundaries,
  updateTextareaContentAndCursor,
  insertTextAtCursor,
} from "./helpers/textUtils";
import { getAllCategories } from '../src/utilities/StorageController'
import Fuse from "fuse.js";
import { debounce } from "./helpers/debounce";
import ButtonWithDropdown from "./components/ButtonWithDropdown";
import CategoryCreator from "./components/CategoryCreator";
import { Category, Sentence, Snippet } from "./types";
import { TagIcon, FolderOpenIcon } from "@heroicons/react/24/outline";
import { ClearButton } from "./components/ClearStateButton";
import ImportExportFileButton from "./components/ImportExportFileButton";
import AddCategoryButton from "./components/AddCategoryButton";
import RemoveCategoryButton from "./components/RemoveCategoryButton";

// Adding Fuse.js options for searching sentences
const fuseOptions = {
  includeScore: true,
  threshold: 0.3, // Lower threshold for stricter matching
  ignoreLocation: true, // Expect the pattern around the center of the average sentence
  distance: 2000, // Allow for matches far from the location
  maxPatternLength: 64, // Default value
  minMatchCharLength: 1, // Minimum number of characters that must be matched
  keys: ["text"], // Assuming you are searching on the 'item' property of the list
  tokenize: true,
  matchAllTokens: false,
  includeMatches: true,
};

const App: React.FC = () => {
  const [texts, setTexts] = useState<
    { id: number; name: string; content: string }[]
  >([
    { id: 1, name: "Feedback", content: "" },
    { id: 2, name: "Feedforward", content: "" },
  ]);
  const [sentences, setSentences] = useState<string[][]>([[], []]);
  const [focusedTextarea, setFocusedTextarea] = useState<number>(1);
  const [loadedSentences, setLoadedSentences] = useState<Sentence[]>([]);
  const [snippets, setSnippets] = useState<Snippet[]>([]);
  const [categoryColor, setCategoryColor] = useState(null); // Initialize categoryColor state
  const [matchedSentences, setMatchedSentences] = useState<{
    [textareaId: number]: string[];
  }>({ 1: [], 2: [] });
  const [fuse, setFuse] = useState<Fuse<Sentence> | undefined>(undefined);
  const [searching, setSearching] = useState<boolean>(false);
  const [asideSearchTerm, setAsideSearchTerm] = useState("");
  const [categories, setCategories] = useState<string[]>([]); // State to hold categories
  const [selectedIcon, setSelectedIcon] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string>("all"); // State to hold selected category
  const [updateCounter, setUpdateCounter] = useState(0);
  const [stateHistory, setStateHistory] = useState([
    {
      texts,
      sentences,
      loadedSentences,
      snippets,
      categoryColor,
      matchedSentences,
    },
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const textAreasRef = useRef<(HTMLTextAreaElement | null)[]>([null, null]);
  const debounceDelay: number = 200;
  let typingTimeout: ReturnType<typeof setTimeout> | null = null;
  const [resetSelection, setResetSelection] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(true); // Track the current mode
  const performSearch = useCallback(
    debounce((searchText: string) => {
      if (fuse) {
        if (searchText.trim().length === 0) {
          setMatchedSentences((prev) => ({ ...prev, [focusedTextarea]: [] }));
        } else {
          const results = fuse.search(searchText);
          const matchedSentencesResults = results.map(
            (result) => result.item.text
          ); // Updated to use "text"
          console.log("Matched Sentences:", matchedSentencesResults);
          setMatchedSentences((prev) => ({
            ...prev,
            [focusedTextarea]: matchedSentencesResults,
          }));
        }
      }
      setSearching(false);
    }, debounceDelay),
    [fuse, focusedTextarea, loadedSentences]
  );

  useEffect(() => {
    const fuseInstance = new Fuse(loadedSentences, fuseOptions);
    setFuse(fuseInstance);
  }, [loadedSentences]);

  // Function to update category color
  const handleCategoryColorChange = (color: any) => {
    setCategoryColor(color);
  };

  const handleTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    id: number
  ) => {
    let newTextValue = e.target.value;
    // Strip new lines and tabs from the text
    newTextValue = newTextValue.replace(/[\n\t]+/g, ' '); // Replaces new lines and tabs with a space. You can replace ' ' with '' if you don't want to add spaces.
  
    // Update texts state with new content
    const newTexts = texts.map((text) =>
      text.id === id ? { ...text, content: newTextValue } : text
    );
    setTexts(newTexts);
  
    // Extract sentences from the updated texts and update sentences state
    const newSentences = newTexts.map((text) => extractSentences(text.content));
    setSentences(newSentences);

    // Identify the current sentence being typed
    const currentTextarea = textAreasRef.current.find(
      (ref, index) => index + 1 === id
    );
    const currentSentence = currentTextarea
      ? identifyCurrentSentence(
          newTexts.find((text) => text.id === id)?.content || "",
          currentTextarea.selectionStart
        )
      : "";

    // Log the current sentence for debugging
    console.log(`Current sentence being typed: ${currentSentence}`);
    console.log(`Handle text change called `);

    // Set searching to true while typing
    setSearching(true);

    // Perform a debounced search with the current sentence
    performSearch(currentSentence);

    saveCurrentState();
  };

  // Modified handleInsertText to accept a sentence parameter
  const handleInsertText = (sentence: string) => {
    const textarea = textAreasRef.current.find(
      (ref, index) => index + 1 === focusedTextarea
    );
    if (textarea) {
      insertTextAtCursor(textarea, sentence + " "); // Add a space after the sentence for readability
      textarea.focus();
      const newText = textarea.value;
      const newTexts = texts.map((text) =>
        text.id === focusedTextarea ? { ...text, content: newText } : text
      );
      setTexts(newTexts);
      handleTextChange(
        {
          target: { value: newText },
        } as React.ChangeEvent<HTMLTextAreaElement>,
        focusedTextarea
      );
    }
  };

  const handleReplaceCurrentSentence = (replacementText: string) => {
    const textarea = textAreasRef.current.find(
      (ref, index) => index + 1 === focusedTextarea
    );
    if (!textarea) return;

    const currentText = texts.find((text) => text.id === focusedTextarea)?.content || "";
    const currentSentence = identifyCurrentSentence(currentText, textarea.selectionStart || 0);

    if (currentSentence) {
      console.log("Replacing:", currentSentence, "with:", replacementText);

      // Calculate the start index of the current sentence to be replaced
      const sentenceStartIndex = currentText.indexOf(currentSentence);
      const beforeSentence = currentText.substring(0, sentenceStartIndex);
      const afterSentence = currentText.substring(sentenceStartIndex + currentSentence.length);

      // Combine the new text without adding extra spaces
      const newText = beforeSentence + replacementText + afterSentence;

      console.log("New text:", newText);

      const newTexts = texts.map((text) =>
        text.id === focusedTextarea ? { ...text, content: newText } : text
      );
      setTexts(newTexts);

      // Update cursor position to the end of the replacement text
      const newCursorPos = beforeSentence.length + replacementText.length;
      updateTextareaContentAndCursor(textarea, newText, newCursorPos);

      handleTextChange(
        {
          target: { value: newText },
        } as React.ChangeEvent<HTMLTextAreaElement>,
        focusedTextarea
      );
    }
};


  useEffect(() => {
    // Function to load sentences for the focused textarea
    const loadSentences = () => {
      const key = `sentences_${focusedTextarea}`;
      const savedSentences = JSON.parse(localStorage.getItem(key) || "[]");
      setLoadedSentences(savedSentences);
    };

    loadSentences();
  }, [focusedTextarea]); // Reload sentences when focusedTextarea changes

  // Reset selection when focus changes
  useEffect(() => {
    setResetSelection(false); // Reset the selection
  }, [focusedTextarea]);

  // If you're using useState
  const [filteredAsideSentences, setFilteredAsideSentences] = useState<
    Sentence[]
  >([]);

  const maxHistorySize = 100; // For example, limit to the last 10 states

  const saveCurrentState = () => {
    const currentState = {
      texts,
      sentences,
      loadedSentences,
      snippets,
      categoryColor,
      matchedSentences,
    };
    let newStateHistory = stateHistory.slice(0, currentIndex + 1); // Remove any "future" states
    newStateHistory.push(currentState);

    // Ensure history doesn't exceed max size by removing oldest entries
    if (newStateHistory.length > maxHistorySize) {
      newStateHistory = newStateHistory.slice(
        newStateHistory.length - maxHistorySize
      );
    }

    setStateHistory(newStateHistory);
    setCurrentIndex(newStateHistory.length - 1);
  };

  const goBack = useCallback(() => {
    if (currentIndex > 0) {
      const previousState = stateHistory[currentIndex - 1];
      // Restore the previous state
      setTexts(previousState.texts);
      setSentences(previousState.sentences);
      setLoadedSentences(previousState.loadedSentences);
      setSnippets(previousState.snippets);
      setCategoryColor(previousState.categoryColor);
      setMatchedSentences(previousState.matchedSentences);

      setCurrentIndex(currentIndex - 1);
    }
  }, [currentIndex, stateHistory]);

  const goForward = useCallback(() => {
    if (currentIndex < stateHistory.length - 1) {
      const nextState = stateHistory[currentIndex + 1];
      // Restore the next state
      setTexts(nextState.texts);
      setSentences(nextState.sentences);
      setLoadedSentences(nextState.loadedSentences);
      setSnippets(nextState.snippets);
      setCategoryColor(nextState.categoryColor);
      setMatchedSentences(nextState.matchedSentences);

      setCurrentIndex(currentIndex + 1);
    }
  }, [currentIndex, stateHistory]);

  const deleteSentenceFromLocal = (sentenceToDelete: string) => {
    const key = `sentences_${focusedTextarea}`;
    let sentences: Sentence[] = JSON.parse(localStorage.getItem(key) || "[]");

    // Filter out the sentence to be deleted
    sentences = sentences.filter(
      (sentence) => sentence.text !== sentenceToDelete
    );

    // Save the updated array back to local storage
    localStorage.setItem(key, JSON.stringify(sentences));

    // Update the loadedSentences and trigger a re-render
    setLoadedSentences(sentences);

    // Optionally, refresh the fuse search index if you're using it for filtering/searching
    const fuseInstance = new Fuse(sentences, fuseOptions);
    setFuse(fuseInstance);
  };

  const updateSentenceFromLocal = (
    sentenceText: string,
    newCategory: string | null,
    newIcon: string | null | undefined,
    newCategoryColor: string | null
  ) => {
    const key = `sentences_${focusedTextarea}`;
    let sentences: Sentence[] = JSON.parse(localStorage.getItem(key) || "[]");

    const updatedSentences = sentences.map((sentence) => {
      if (sentence.text === sentenceText) {
        const updatedSentence: Sentence = { ...sentence };
        if (newCategory !== null && newCategory.trim() !== "") {
          updatedSentence.category = newCategory;
        }
        if (newIcon !== null && newIcon !== undefined) {
          updatedSentence.icon = newIcon;
        }
        if (newCategoryColor !== null && newCategoryColor.trim() !== "") {
          updatedSentence.categoryColor = newCategoryColor;
        }
        setUpdateCounter((prevCounter) => prevCounter + 1);
        return updatedSentence;
      }
      return sentence;
    });

    localStorage.setItem(key, JSON.stringify(updatedSentences));

    setLoadedSentences(updatedSentences);

    // Optionally, refresh the fuse search index if you're using it for filtering/searching
    // Ensure you have setFuse and fuseOptions defined elsewhere in your code to use this part
    const fuseInstance = new Fuse(updatedSentences /* fuseOptions */);
    setFuse(fuseInstance);
};


useEffect(() => {
  if (fuse && asideSearchTerm.trim() !== "") {
    let results = fuse.search(asideSearchTerm).map((result) => result.item);
    if (selectedIcon) {
      // TypeScript should now recognize the `icon` property without error.
      results = results.filter(sentence => sentence.icon === selectedIcon);
    }
    
    setFilteredAsideSentences(results);
  } else {
    let sentences = loadedSentences;
    if (selectedIcon) {
      sentences = sentences.filter(sentence => sentence.icon === selectedIcon);
    }
    setFilteredAsideSentences(sentences);
  }
}, [asideSearchTerm, fuse, loadedSentences, selectedIcon]);


  useEffect(() => {
    // Function to handle storage update
    const handleStorageUpdate = () => {
      console.log("Local storage updated. Reloading sentences...");

      // Implement the logic to reload sentences based on the updated local storage.
      // This could mean re-fetching the sentences from local storage and updating the state.
      // For example:
      const key = `sentences_${focusedTextarea}`;
      const savedSentences = JSON.parse(localStorage.getItem(key) || "[]");
      setLoadedSentences(savedSentences);
      console.log("we should update now");

      // Create a new Fuse instance with the updated sentences
      const fuseInstance = new Fuse<{ text: string }>(
        savedSentences,
        fuseOptions
      );
      setFuse(fuseInstance);
      setUpdateCounter((prevCounter) => prevCounter + 1);
    };

    // Add an event listener for the 'localstorage-update' event
    window.addEventListener("localstorage-update", handleStorageUpdate);

    // Cleanup the listener when the component unmounts
    return () => {
      window.removeEventListener("localstorage-update", handleStorageUpdate);
    };
  }, [focusedTextarea]); // Include dependencies if needed

  useEffect(() => {
    const fetchCategories = async () => {
      const allCategories = await getAllCategories(); // Fetch based on some criteria
      setCategories(allCategories);
    };

    fetchCategories();
  }, [updateCounter]); // Runs when selectedCategory changes

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      // Check for CMD/CTRL + Z for undo (goBack)
      if ((event.metaKey || event.ctrlKey) && event.key === "z") {
        if (!event.shiftKey) {
          // Prevent default to avoid conflicting browser shortcuts
          event.preventDefault();
          goBack();
        }
      }

      // Check for CMD/CTRL + Shift + Z for redo (goForward)
      if (
        (event.metaKey || event.ctrlKey) &&
        event.shiftKey &&
        event.key === "Z"
      ) {
        event.preventDefault();
        goForward();
      }
    };

    // Add the event listener
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [goBack, goForward, currentIndex, stateHistory]);

  const emojis = [
    { emoji: '🚨', label: 'Hmm..' },
    { emoji: '😐', label: 'OK' },
    { emoji: '👍', label: 'Great' },
    { emoji: '🙌', label: 'Super' },
  ];



  const toggleIconFilter = (emoji:string) => {
    if (selectedIcon === emoji) {
      setSelectedIcon(null); // Deselect if the same emoji is clicked
    } else {
      setSelectedIcon(emoji); // Select new emoji
    }
  };

  return (
    <div className="flex min-h-full">
      <main className="flex-1 overflow-auto p-4">
        <div className="flex justify-between items-center mb-2">
          <h1 className="h-14 space-x-3 m-2 py-2 flex justify-between items-center">
            <span className="font-bold text-blue-500">Feedback.Fan</span><span className="hidden lg:block text-zinc-400">Makes feedback a
            breeze</span>
          </h1>
          <ImportExportFileButton></ImportExportFileButton>
          <SetTextareaModeToggle
            editMode={editMode}
            setEditMode={setEditMode}
          />
        </div>
        <div className="flex flex-col h-[80vh]">
          {texts.map((text, index) => (
            <div key={index} className="relative h-full ">
              {editMode ? (
                <div className=" border border-gray-200 rounded items-center">
                  <div className="flex-row p-3">
                    <div className="flex  w-full justify-between flex-row md:items-center items-right gap-y-2 md:gap-y-0 gap-x-2 ">
                    <h2 className="text-base font-medium leading-7 text-slate-900">
                            {text.name}
                          </h2>
                    <div className="w-full flex flex-row gap-3 items-center justify-end">
                      <button
                        type="button"
                        onClick={goBack}
                        disabled={currentIndex <= 0}
                        className="inline-flex items-center  gap-x-1.5 rounded-md bg-slate-200/40 px-2.5 py-1.5 text-sm font-semibold text-slate-700 shadow-sm hover:bg-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-300"
                      >
                        <ArrowUturnLeftIcon
                          className="-ml-0.5 h-5 w-5"
                          aria-hidden="true"
                        />
                        Back
                      </button>
                      <button
                        type="button"
                        onClick={goForward}
                        disabled={currentIndex <= 0}
                        className="inline-flex items-center gap-x-1.5 rounded-md bg-slate-200/40 px-2.5 py-1.5 text-sm font-semibold text-slate-700 shadow-sm hover:bg-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-300"
                      >
                        Forward
                        <ArrowUturnRightIcon
                          className="-ml-0.5 h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                      <span className="text-black/30">
                        |
                      </span>
                      <div className="">
                        <ClearButton
                          textAreasRef={textAreasRef}
                          texts={texts}
                          setTexts={setTexts}
                          setSentences={setSentences}
                          setMatchedSentences={setMatchedSentences}
                        />
                      </div>{" "}
                      <span className="text-black/30">
                        |
                      </span>
                      <div className="">
                        <CopyButton content={text.content} />
                      </div>
                    </div>
                    </div>
                    
                  </div>
                  <div className="relative">
                    <textarea
                      ref={(ref) => (textAreasRef.current[index] = ref)}
                      value={text.content}
                      onChange={(e) => handleTextChange(e, text.id)}
                      className="w-full min-h-48 -mb-2 border-1 border-gray-200 focus:ring-0 flex-1 p-4 leading-relaxed"
                      onFocus={() => setFocusedTextarea(text.id)}
                    />
                  </div>
                  <div className="p-2 bg-slate-50">
                  <div className="w-full flex flex-row">
                      {searching ? (
                        <div className="flex items-center">
                         
                          <span className="text-gray-500 p-2 text-sm animate-pulse">
                            Searching...
                          </span>
                        </div>
                      ) : loadedSentences.length === 0 ? (
                        <div className="flex items-center">
                        
                          <span className="text-gray-500 p-2 text-sm">
                            Nothing saved yet..
                          </span>
                        </div>
                      ) : matchedSentences[text.id] &&
                        matchedSentences[text.id].length > 0 ? (
                        <div className="flex items-center">
                         
                          <span className="text-gray-500 p-2 text-sm">
                            Showing {matchedSentences[text.id].length}{" "}
                            {matchedSentences[text.id].length === 1
                              ? "match"
                              : "matches"}
                          </span>
                        </div>
                      ) : (
                        <div className="flex items-center">
                        
                          <span className="text-gray-500 p-2 text-sm">
                            No matches found.
                          </span>
                        </div>
                      )}
                    </div>
                    {matchedSentences[text.id] &&
                    matchedSentences[text.id].length > 0
                      ? matchedSentences[text.id].map(
                          (sentenceText, sentenceIndex) => {
                            // Find the full sentence object from loadedSentences to get category info
                            const fullSentence = loadedSentences.find(
                              (s) => s.text === sentenceText
                            );
                            const sentenceCategory =
                              fullSentence?.category || "";
                            const sentenceCategoryColor =
                              fullSentence?.categoryColor || "#ffffff"; // default to white if no color is set
                            const sentenceIcon =
                              fullSentence?.icon || "#ffffff"; // default to white if no color is set

                            return (
                              <div
                                key={sentenceIndex}
                                className="inline-block "
                              >
                                <button
                                  onClick={() =>
                                    handleReplaceCurrentSentence(sentenceText)
                                  }
                                  className={`flex items-center text-left rounded-md px-2 py-2 text-sm mr-1 mb-1 font-medium text-zinc-950/50 border border-zinc-900/[.05]  ${sentenceCategoryColor}`}
                                >
                                  {sentenceIcon ? (
                                    <>
                                      <span className=" p-1 mr-1 rounded stroke-1 text-xs">
                                        {sentenceIcon}
                                      </span>
                                    </>
                                  ) : (
                                    <span className="mr-2 stroke-1">
                                      {sentenceText}
                                    </span>
                                  )}
                                  {sentenceCategory ? (
                                    <>
                                      <span className=" bg-zinc-500/[.05] p-1 mr-2 rounded stroke-1 text-xs">
                                        {sentenceCategory}
                                      </span>{" "}
                                      <span className="mr-2 stroke-1">
                                        {sentenceText}
                                      </span>{" "}
                                    </>
                                  ) : (
                                    <span className="mr-2 stroke-1">
                                      {sentenceText}
                                    </span>
                                  )}
                                </button>
                              </div>
                            );
                          }
                        )
                      : null}
                      </div>
                </div>
              ) : (
                <IdentifiedSentences
                  sentences={sentences[index]}
                  focusedTextarea={text.id}
                  resetSelection={resetSelection}
                  onResetSelection={() => setResetSelection(false)}
                  textAreaName={text.name}
                  setEditMode={setEditMode}
                />
              )}
            </div>
          ))}
        </div>
      </main>
      <aside className="w-4/12 min-w-96 h-screen overflow-x-hidden p-4 pt-0 mt-4 border-l border-gray-200 overflow-y-scroll">
  <div className="sticky top-0 bg-white p-2 z-10">
    {/* Category Buttons */}
    <div className="mb-2 flex flex-wrap">
      <button
        className={`flex items-center m-1 px-2 rounded ${
          selectedCategory === "all"
            ? "bg-gray-100 text-gray-700"
            : "text-gray-500 hover:text-gray-700"
        } rounded-lg px-3 py-1 text-sm font-medium focus:outline-none`}
        onClick={() => setSelectedCategory("all")}
      >
        <FolderOpenIcon className="h-4 w-4 text-black/50 mr-1" /> All{" "}
        <span className="ml-1 text-gray-300 bg-slate-50 p-1 rounded">
          {filteredAsideSentences.length}
        </span>
      </button>

      {categories.map((category, index) => (
        <button
          key={index}
          className={` m-1 px-2 py-1 rounded ${
            selectedCategory === category
              ? "bg-gray-100 text-gray-700"
              : "text-gray-500 hover:text-gray-700"
          } rounded-lg px-3  text-sm font-medium focus:outline-none`}
          onClick={() => setSelectedCategory(category)}
        >
          {category}
          <span className="ml-1 text-gray-300 bg-slate-50 rounded">
            {filteredAsideSentences.filter(
              (sentence) => sentence.category === category
            ).length}
          </span>
        </button>
      ))}
      <AddCategoryButton />
      <RemoveCategoryButton />

    </div>
    <div className="icon-filter flex justify-between">
      <div>
    {emojis.map((emoji, index) => (
        <div
          key={emoji.label}
          onClick={() => toggleIconFilter(emoji.emoji)}
         
          className={`cursor-pointer hover:bg-gray-200 relative inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10 ${
            selectedIcon === emoji.emoji ? 'bg-gray-400' : 'bg-white'
          } ${index === 0 ? 'rounded-l-md ' : ''} ${index === emojis.length - 1 ? 'rounded-r-md' : '-ml-px'}`}
        >
          {emoji.emoji} <span className="ml-1 text-gray-500 text-[10px]">{emoji.label}</span>
        </div>
      ))}
      </div>
  <button onClick={() => setSelectedIcon(null)}><XMarkIcon className="h-5 w-45  bg-gray-50 hover:bg-gray-100 text-gray-400 rounded"></XMarkIcon></button>
</div>
    {/* Search Input */}
    <div className="relative mt-2 flex items-center">
      <input
        type="text"
        name="search"
        id="search"
        value={asideSearchTerm}
        onChange={(e) => setAsideSearchTerm(e.target.value)}
        className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />
      <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
        <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400">
          <MagnifyingGlassIcon className="h-3 w-4" />
          Search
        </kbd>
      </div>
    </div>
  </div>

  <div className="max-h-[calc(100vh-100px)] mb-4">
    <span className="text-gray-400 text-sm mb-4">
      Showing{" "}
      {
        filteredAsideSentences.filter(
          (sentence) =>
            (selectedCategory === "all" ||
              sentence.category === selectedCategory) &&
            sentence.text.toLowerCase().includes(asideSearchTerm.toLowerCase())
        ).length
      }{" "}
      sentences
    </span>
    <div className="mt-2 mb-2"></div>
{/* Update the map function to include category filtering */}
{filteredAsideSentences
  .filter(
    (sentence) =>
      (selectedCategory === "all" ||
        sentence.category === selectedCategory) &&
      sentence.text.toLowerCase().includes(asideSearchTerm.toLowerCase())
  )
  .reverse() // Reverse the array to display newest items first
  .map((sentence, index) => (
    <SentenceWithCategoryUpdate
      key={index}
      sentence={sentence.text}
      category={sentence.category}
      icon={sentence.icon}
      handleInsertText={() => handleInsertText(sentence.text)}
      deleteSentenceFromLocal={() => deleteSentenceFromLocal(sentence.text)}
      updateSentenceFromLocal={(newCategory, icon) =>
        updateSentenceFromLocal(
          sentence.text,
          newCategory,
          icon,
          categoryColor
        )
      } // Pass categoryColor from state
      handleCategoryColorChange={handleCategoryColorChange} // Pass down the function to change color
      categoryColor={sentence.categoryColor || null} // Initial category color
    />
  ))}

  </div>
</aside>



    </div>
  );
};

export default App;
