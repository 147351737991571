// textUtils.ts

interface TextSnippet {
  text: string;
  category: string;
  clicks: number;
  icon: string;
  categoryColor: string; // Add categoryColor property
}

/**
 * Extracts sentences from the given text, including punctuation.
 * @param text The full text from which to extract sentences.
 * @returns An array of sentences.
 */
export const extractSentences = (text: string): string[] => {
    const sentencesWithPunctuationRegex = /[^.!?]+[.!?]*/g;
    return text.match(sentencesWithPunctuationRegex) || [];
  };
  
  /**
   * Identifies the current sentence being typed based on the cursor position.
   * @param text The full text from the textarea.
   * @param cursorPos The current cursor position within the textarea.
   * @returns The current sentence being typed.
   */
  export const identifyCurrentSentence = (text: string, cursorPos: number): string => {
    // Return an empty string if the text is empty or the cursor is at the start.
    if (!text || cursorPos === 0) {
      return '';
    }
  
    const textBeforeCursor = text.substring(0, cursorPos);
  
    // Look for the last punctuation followed by zero or one non-whitespace character before the cursor.
    const punctuationWithOneCharRegex = /[.!?]\s*.$/;
  
    // If the text immediately before the cursor matches this pattern, return an empty string.
    if (punctuationWithOneCharRegex.test(textBeforeCursor)) {
      return '';
    }
  
    // Match everything after the last occurrence of punctuation before the cursor.
    const sentenceBoundaryRegex = /(?:[.!?]\s*)([^.!?]+)$/;
  
    // Attempt to match the pattern in the substring before the cursor
    const match = textBeforeCursor.match(sentenceBoundaryRegex);
  
    // If a match is found, return it; otherwise, return the whole text before the cursor as the current sentence
    if (match && match[1]) {
      return match[1].trim();
    } else {
      return textBeforeCursor.trim();
    }
  };
  
  
  


 /**
 * Finds the start and end indices of the sentence at the given cursor position.
 */
 export function findSentenceBoundaries(text: string, cursorPos: number): { start: number; end: number } {
    const sentenceBoundaryRegex = /([.!?;]\s+)/g;
    const textBeforeCursor = text.substring(0, cursorPos);
    const textAfterCursor = text.substring(cursorPos);
    let start = 0;
    let end = text.length;

    // Matches for sentence start boundaries before the cursor
    let sentenceStartMatches = Array.from(textBeforeCursor.matchAll(sentenceBoundaryRegex));

    // Determine the start of the sentence
    if (sentenceStartMatches.length > 0) {
        let lastMatchBeforeCursor = sentenceStartMatches[sentenceStartMatches.length - 1];
        start = lastMatchBeforeCursor.index! + lastMatchBeforeCursor[0].length;
    }

    // Matches for sentence end boundaries after the cursor
    let sentenceEndMatches = Array.from(textAfterCursor.matchAll(sentenceBoundaryRegex));

    // Determine the end of the sentence
    if (sentenceEndMatches.length > 0) {
        let firstMatchAfterCursor = sentenceEndMatches[0];
        end = cursorPos + firstMatchAfterCursor.index!;
    }

    return { start, end };
}

/**
 * Inserts text at the current cursor position within a textarea and moves the cursor
 * to the right of the inserted text.
 * @param textarea The textarea element where text is to be inserted.
 * @param textToInsert The text to be inserted at the cursor's current position.
 */
export const insertTextAtCursor = (textarea: HTMLTextAreaElement, textToInsert: string): void => {
  // Get the current cursor position and textarea's text
  const startPos = textarea.selectionStart ?? 0;
  const endPos = textarea.selectionEnd ?? 0;
  const textBefore = textarea.value.substring(0, startPos);
  const textAfter = textarea.value.substring(endPos);

  // Check if there's already a space at the beginning of the cursor
  const hasSpaceBeforeCursor = startPos === 0 || textBefore.charAt(startPos - 1) === ' ';

  // Prepend a space if there isn't already one
  const newTextToInsert = hasSpaceBeforeCursor ? textToInsert : ' ' + textToInsert;

  // Insert the text at the cursor position
  const newText = textBefore + newTextToInsert + textAfter;

  // Update the textarea with the new text
  textarea.value = newText;

  // Calculate the new cursor position (right after the inserted text)
  const newCursorPos = startPos + newTextToInsert.length;

  // Set the new cursor position
  textarea.setSelectionRange(newCursorPos, newCursorPos);

  // Manually trigger the input event for the textarea
  const event = new Event('input', { bubbles: true });
  textarea.dispatchEvent(event);
};


  
  
  /**
   * Updates the textarea content and cursor position.
   */
  export function updateTextareaContentAndCursor(textarea: HTMLTextAreaElement, text: string, newPos: number): void {
    textarea.value = text;
    setTimeout(() => {
      textarea.focus();
      textarea.setSelectionRange(newPos, newPos);
    }, 0);
  }

  
  export const replaceSentenceInText = (text: string, replacementText: string, start: number, end: number): string => {
    // Check if the original sentence ends with a punctuation mark.
    //const originalSentenceEndsWithPunctuation = ['.', '?', '!'].includes(text[end - 1]);

    // If the replacement text does not end with a punctuation mark but the original does, append the corresponding punctuation.
   // if (!replacementText.match(/[.!?]$/) && originalSentenceEndsWithPunctuation) {
   //     replacementText += text[end - 1];
   // }

    // Directly replace the text without adding an extra space or modifying the end boundary unnecessarily.
    return `${text.substring(0, start)}${replacementText}${text.substring(end)}`;
};


export const getAllCategories = (): string[] => {
  const allCategories: Set<string> = new Set();
  
  // Iterate through localStorage keys to find all unique categories
  for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith('sentences_')) {
          const sentences: TextSnippet[] = JSON.parse(localStorage.getItem(key) || '[]');
          sentences.forEach(sentence => {
              allCategories.add(sentence.category);
          })
      }
  }

  // Convert Set to Array and return
  return Array.from(allCategories);
};





