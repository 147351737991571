import React, { Fragment, useRef } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, CloudArrowDownIcon, CloudArrowUpIcon, ArrowPathRoundedSquareIcon } from '@heroicons/react/24/outline';
import { clearLocalStorage, exportToLocalFile, importFromLocalFile } from '../utilities/StorageController';

const items = [
  {
    name: 'Import from File',
    onClick: (inputFileRef:any) => inputFileRef.current && inputFileRef.current.click(),
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Clear all saved data',
    onClick: (clearLocalStorage),
    icon: ArrowPathRoundedSquareIcon,
  },
];

function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ');
}

export default function ImportExportFileButton() {
  const inputFileRef = useRef(null);

  return (
    <div className="inline-flex rounded-md shadow-sm">
      {/* Separate Export Button */}
      <button
        type="button"
        onClick={exportToLocalFile} // Add onClick handler here
        className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
      ><CloudArrowDownIcon className="h-5 w-5 mr-1" aria-hidden="true" />
        Save as file
      </button>
      <Menu as="div" className="relative -ml-px block">
        <Menu.Button className="relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
          <span className="sr-only">Open options</span>
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {items.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <button
                      onClick={() => item.onClick(inputFileRef)}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'px-4 py-2 text-sm flex items-center justify-start'
                      )}
                    >
                      {item.icon && <item.icon className="mr-2 h-5 w-5" aria-hidden="true" />}
                      {item.name}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <input
            type="file"
            ref={inputFileRef}
            style={{ display: 'none' }}
            onChange={(e) => {
                if (e.target.files?.[0]) {
                importFromLocalFile(e.target.files[0]);
                e.target.value = ''; // Reset the input value
                }
            }}
            />

    </div>
  );
}
