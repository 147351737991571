// IdentifiedSentence.tsx

import React from 'react';

interface IdentifiedSentenceProps {
  sentence: string;
  isCurrent: boolean;
  onClick?: () => void; // Now correctly marked as optional
  style?: React.CSSProperties; // Prop to accept styles
  className?: string; // Prop to accept class name
}

const IdentifiedSentence: React.FC<IdentifiedSentenceProps> = ({ sentence, isCurrent, onClick, style, className }) => {
  return (
    <span
      className={`cursor-pointer inline-flex items-center rounded-md  px-2 py-2 text-sm mb-1 font-medium hover:bg-yellow-50 hover:border-yellow-100 text-gray-600 ring-1 ring-inset ring-gray-500/10 ${isCurrent ? "bg-yellow-50 border-yellow-400" : "bg-white border-gray-200"} rounded border ${className || ""}`}
      onClick={onClick}
      style={style} // Apply the received styles
    >
      {sentence}
    </span>
  );
};

export default IdentifiedSentence;
