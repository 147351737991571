import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { getAllCategories } from '../utilities/StorageController';

// Specify the type for classNames function parameter
function classNames(...classes: (string | undefined | null)[]) {
  return classes.filter(Boolean).join(' ');
}

interface ComboBoxProps {
  onSelectionChange: (selected: string | null) => void;
}

export default function ComboBox({ onSelectionChange }: ComboBoxProps) {
  const [selected, setSelected] = useState<string | null>(null);
  const [categories, setCategories] = useState<string[]>([]);
  const listboxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const categories = getAllCategories();
    // Prepend an empty string to the categories array
    setCategories(['', ...categories]);
  }, []);

  const handleSelectionChange = (value: string | null) => {
    setSelected(value);
    onSelectionChange(value);
  };

  return (
    <Listbox value={selected} onChange={handleSelectionChange}>
      {({ open }) => (
        <>
        
          <div className="relative mx-2" ref={listboxRef}>
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{selected ? selected : 'Select category'}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className="absolute z-10 mt-1 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                style={{ maxHeight: `${categories.length * 36}px` }} // Adjust height based on the number of items including the blank option
              >
                {categories.map((category) => (
                  <Listbox.Option
                    key={category}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={category}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {category || 'Select a category'} {/* Render 'Select a category' if the category is empty */}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
