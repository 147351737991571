import React, { useState, useEffect, FC, useRef } from 'react';
import { ChevronDownIcon, BackspaceIcon } from '@heroicons/react/24/outline';
import ComboBox from './CategoryCombobox';

interface SentenceWithCategoryUpdateProps {
  sentence: string | null;
  category?: string | null;
  icon?: string | null;
  emoji?: string | null;
  handleInsertText: (sentence: string | null) => void;
  handleCategoryColorChange: (color: string) => void;
  deleteSentenceFromLocal: (sentence: string | null) => void;
  updateSentenceFromLocal: (
    newCategory: string,
    icon: string | null | undefined,
    categoryColor: string | null | undefined
  ) => void;
  categoryColor: string | null;
}

const SentenceWithCategoryUpdate: FC<SentenceWithCategoryUpdateProps> = React.memo(
  ({
    sentence,
    category,
    icon,
    handleInsertText,
    deleteSentenceFromLocal,
    updateSentenceFromLocal,
    categoryColor,
    handleCategoryColorChange
  }) => {
    const [selectedEmojiIndex, setSelectedEmojiIndex] = useState<number | null>(null);
    const [expanded, setExpanded] = useState(false);
    const [selectedEmoji, setSelectedEmoji] = useState<string | null>(null);
    const ref = useRef<HTMLDivElement>(null); // Create a ref for the component

    // Effect to handle clicks outside of the component
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setExpanded(false);
          console.log("click outside")
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  // Effect to update sentence when categoryColor changes
  useEffect(() => {
    console.log("categoryColor Changed")
    if (selectedEmoji && categoryColor) {
      updateSentenceFromLocal(category ?? '', selectedEmoji, categoryColor);
    }
  }, [selectedEmoji]);

  const handleCategoryClick = (e: React.MouseEvent<HTMLDivElement>) => {
    console.log("category clicked")
    e.stopPropagation();
    setExpanded(!expanded);
  };
  
  const handleCategoryChange = (newCategory: string | null) => {
    if (newCategory !== category) { 
      updateSentenceFromLocal(newCategory ?? '', icon, categoryColor);
    }
  };

  const emojis = [
    { emoji: '🚨', label: 'Hmm..' },
    { emoji: '😐', label: 'OK' },
    { emoji: '👍', label: 'Great' },
    { emoji: '🙌', label: 'Super' },
  ];

  const handleEmojiSelect = (emoji: string, index: number) => {
    const selectedColor = index === 0 ? 'bg-red-100/60' :
      index === 1 ? 'bg-orange-100/60' :
      index === 2 ? 'bg-blue-100/60' : 'bg-green-100/60';
  
    handleCategoryColorChange(selectedColor);
    setSelectedEmojiIndex(index);
    setSelectedEmoji(emoji);
    console.log("Emoji selected clicked")
  };



  return (
    <div ref={ref} onClick={() => handleInsertText(sentence)} className={`flex flex-col cursor-pointer rounded-md px-2 py-2 text-sm mb-2 font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 ${categoryColor}`}>
<div className={`flex items-center mb-2 ${expanded ? 'bg-black/[0.03]' : 'hover:bg-black/[0.03]'} rounded p-2`} onClick={handleCategoryClick}>
        <div className="flex items-center gap-2">
          <div className="flex items-center">
            {icon && <span className=" h-5 w-5 mr-2">{icon}</span>}
            {category && <span className="w-full">{category}</span>}
          </div>
          <ChevronDownIcon className={`h-5 w-5 text-zinc-950/50 transform ${expanded ? 'rotate-180' : ''}`} aria-hidden="true" />
        </div>
        <div className="ml-auto">
          <BackspaceIcon
            onClick={(e) => {
              e.stopPropagation();
              deleteSentenceFromLocal(sentence);
            }}
            className="h-5 w-5 text-zinc-950/60 cursor-pointer hover:text-zinc-950"
          />
        </div>
      </div>
      {expanded && (
        <div onClick={(e) => {
          e.stopPropagation();
        }}  className='bg-black/[0.03] -mt-2 max-w-96'>
          <ComboBox onSelectionChange={handleCategoryChange} />
          <div  onClick={(e) => {
              e.stopPropagation();
            }} 
          className='p-2'>
            <span className="isolate inline-flex rounded-md shadow-sm mt-2">
              {emojis.map(({ emoji, label }, index) => (
                <div
                  key={label}
                  className={`relative flex items-center ${index === 0 ? 'rounded-l-md' : index === emojis.length - 1 ? 'rounded-r-md' : ''} bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 ${selectedEmojiIndex === index ? 'border border-gray-500' : ''}`}
                  onMouseDown={(e) => {
                    e.preventDefault(); // Prevents the default behavior (double-click selection)
                    handleEmojiSelect(emoji, index);
                  }}
                  
                  aria-label={label}
                  style={{ filter: `brightness(${selectedEmojiIndex === index ? '90%' : '100%'})` }}
                >
                  <span className='mr-1'>{emoji}</span><span className="text-xs">{label}</span>
                </div>
              ))}
            </span>
          </div>
        </div>
      )}
      <div className="text-left px-1">{sentence}</div>
    </div>
  );
});

export default SentenceWithCategoryUpdate;
