import { useState } from 'react';
import { CheckIcon, ClipboardIcon } from '@heroicons/react/24/outline';

interface CopyButtonProps {
  content: string;
}

export const CopyButton: React.FC<CopyButtonProps> = ({ content }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    // Create a temporary textarea element
    const textarea = document.createElement('textarea');
    
    // Set the value of the textarea to the content to be copied
    textarea.value = content;

    // Append the textarea to the document
    document.body.appendChild(textarea);

    // Select the content
    textarea.select();

    // Copy the selected content to clipboard
    document.execCommand("copy");

    // Remove the textarea from the document
    document.body.removeChild(textarea);

    // Update the state to indicate that content has been copied
    setCopied(true);

    // Reset the copied state after 2 seconds
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <button
      type="button"
      onClick={handleCopyClick}
      className={`inline-flex items-center gap-x-1.5 rounded-md ${copied ? 'bg-blue-600/70 hover:bg-blue-600/70 text-white' : 'bg-slate-200/40'} px-2.5 py-1.5 text-sm font-semibold text-slate-700 shadow-sm hover:bg-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-300`}
    >
      {copied ? (
        <>
          <CheckIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Copied
        </>
      ) : (
        <>
          <ClipboardIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Copy
        </>
      )}
    </button>
  );
};
